import dynamic from 'next/dynamic'
import { Control } from './controls'
const QuillEditor = dynamic(() => import('./QuillEditor'), { ssr: false })

interface Props {
  className?: string
  containerClassName?: string
  readOnly?: boolean
  placeholder?: string
  controls?: Array<Control | Array<Control>>
  value?: string
  defaultValue?: string
  onChange?: (value: string) => void
  borderlessMode?: boolean
}

export function TextEditor(props: Props) {
  return <QuillEditor {...props} />
}
