import { MutableRefObject, useEffect, useState } from 'react'

export function useContainerDimensions<T extends HTMLElement>(
  containerRef: MutableRefObject<T>,
  defaultDimensions = { width: 0, height: 0 }
): { width: number; height: number } {
  const [dimensions, setDimensions] = useState<{
    width: number
    height: number
  }>(defaultDimensions)

  useEffect(() => {
    const container = containerRef?.current

    if (container) {
      const observer = new ResizeObserver(() => {
        setDimensions({
          width: container.scrollWidth,
          height: container.scrollHeight
        })
      })

      observer.observe(container)

      return () => observer.disconnect()
    }
  }, [containerRef])

  return dimensions
}
