import 'katex/dist/katex.min.css'
import katex from 'katex'

const formulaStartDelimiter = '__FORMULA_START__'
const formulaEndDelimiter = '__FORMULA_END__'
const formulasRegex = new RegExp(`${formulaStartDelimiter}(.*?)${formulaEndDelimiter}`, 'g')

export function renderLatexFormula(formula: string, element: HTMLElement) {
  katex.render(formula, element, {
    throwOnError: false,
    errorColor: '#f00'
  })
}

export function encodeFormulasHTML(html: string): string {
  if (html.indexOf('math-formula')) {
    const tempDiv = document.createElement('div')

    try {
      tempDiv.innerHTML = html
      tempDiv.querySelectorAll('.math-formula').forEach((element: HTMLElement) => {
        if (element.getElementsByClassName('katex').length > 0) {
          const formula = element.dataset.value
          const formulaNode = document.createTextNode(formulaStartDelimiter + formula + formulaEndDelimiter)

          element.replaceWith(formulaNode)
        } else {
          element.remove()
        }
      })
      html = tempDiv.innerHTML
    } finally {
      tempDiv.remove()
    }
  }

  return html
}

export function decodeFormulasHTML(html: string): string {
  if (html.match(formulasRegex)) {
    const codeReplacements = new Map<string, string>()

    codeReplacements.set('&lt;', '<')
    codeReplacements.set('&gt;', '>')

    html = html.replace(formulasRegex, (_, formula) => {
      codeReplacements.forEach((codeValue, code) => {
        formula = formula.replace(new RegExp(code, 'g'), codeValue)
      })

      const tempElement = document.createElement('span')

      try {
        tempElement.classList.add('math-formula')
        tempElement.dataset.value = formula
        tempElement.contentEditable = 'false'
        renderLatexFormula(formula, tempElement)

        return tempElement.outerHTML
      } finally {
        tempElement.remove()
      }
    })
  }

  return html
}
