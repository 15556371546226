import { Icon } from '@chakra-ui/react'

const FieldLargeIcon = (props) => {
  return (
    <Icon viewBox="0 0 140 140" {...props}>
      <rect
        x="1.7888"
        y="1.7039"
        width="136.94"
        height="136.68"
        fill="none"
        stroke="currentColor"
        stroke-width=".63343"
      />
      <text
        transform="scale(1.1219 .89138)"
        x="22.162863"
        y="105.33537"
        fill="currentColor"
        font-family="sans-serif"
        font-size="123.29px"
        stroke-width="3.0823"
      >
        <tspan x="22.162863" y="105.33537" stroke-width="3.0823">
          L
        </tspan>
      </text>
      <path d="m21.187 119.99 99.622-0.0989" fill="none" stroke="currentColor" stroke-width="7.9639" />
    </Icon>
  )
}

export default FieldLargeIcon
