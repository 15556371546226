import { createStandaloneToast } from '@chakra-ui/react'

const toast = createStandaloneToast()

function showMessage(type: 'info' | 'warning' | 'success' | 'error', message, duration = 5000) {
  toast({
    title: message,
    status: type,
    position: 'top-right',
    isClosable: true,
    duration
  })
}

export function showInfoMessage(message: string, duration = 5000) {
  showMessage('info', message, duration)
}

export function showSuccessMessage(message: string, duration = 5000) {
  showMessage('success', message, duration)
}

export function showErrorMessage(message: string, duration = 5000) {
  showMessage('error', message, duration)
}
