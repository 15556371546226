import { useStore } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { createStore } from 'zustand/vanilla'

interface LessonPathModeState {
  usingNewLessonPathMode: boolean
  setUsingNewLessonPathMode: (usingNewLessonPathMode: boolean) => void
}

const LessonPathModeStore = createStore(
  persist<LessonPathModeState>(
    (set) => ({
      usingNewLessonPathMode: false,
      setUsingNewLessonPathMode: (usingNewLessonPathMode: boolean) => set({ usingNewLessonPathMode })
    }),
    {
      name: 'lesson-path-mode',
      storage: createJSONStorage(() => localStorage)
    }
  )
)

export const getLessonPathModeStore = () => LessonPathModeStore.getState()

export const useLessonPathModeStore = (selector: (state: LessonPathModeState) => any) =>
  useStore(LessonPathModeStore, selector)
