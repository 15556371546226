import { useCallback, useRef } from 'react'

export function useDebouncedCallback(callback, milliseconds, dependencies = []) {
  const timeout = useRef<any>()

  return useCallback(
    (...args) => {
      const later = () => {
        clearTimeout(timeout.current)
        callback(...args)
      }

      clearTimeout(timeout.current)

      if (callback) {
        timeout.current = setTimeout(later, milliseconds)
      }
    },
    [callback, milliseconds, ...dependencies]
  )
}
