import { useEffect, useState } from 'react'

interface Props {
  pollInterval?: number
}

const DEFAULT_POLL_TIME_MS = 5 /* minutes */ * 60 * 1000

export function useDeployDetector({ pollInterval = DEFAULT_POLL_TIME_MS }: Props = {}): boolean {
  const [newDeployDetected, setNewDeployDetected] = useState<boolean>(false)

  const getServerBuildId = async (): Promise<string> => {
    const response = await fetch('/api/build-id')
    const { buildId } = await response.json()

    return buildId
  }

  useEffect(() => {
    const timerTask = setInterval(async () => {
      const buildId = await getServerBuildId()

      if (buildId != process.env.BUILD_ID) {
        setNewDeployDetected(true)
      }
    }, pollInterval)

    return () => clearInterval(timerTask)
  }, [pollInterval])

  return newDeployDetected
}
