import { Router } from 'next/router'
import { useEffect } from 'react'

export function usePageLeaveCallback(callback: () => void | Promise<void>) {
  useEffect(() => {
    const handleLeave = async () => {
      await callback()
    }

    Router.events.on('routeChangeStart', handleLeave)
    window.addEventListener('beforeunload', handleLeave)

    return () => {
      Router.events.off('routeChangeStart', handleLeave)
      window.removeEventListener('beforeunload', handleLeave)
    }
  }, [callback])
}
