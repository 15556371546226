import { useStore } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { createStore } from 'zustand/vanilla'

export interface PublisherState {
  forAssessmentQuestionSelected: boolean
}

interface PublisherStateMutation {
  setForAssessmentQuestionSelected: (value: boolean) => void
}

export const publisherStore = createStore(
  immer<PublisherState & PublisherStateMutation>((set) => ({
    forAssessmentQuestionSelected: false,

    setForAssessmentQuestionSelected(value: boolean) {
      set((state: PublisherState) => {
        state.forAssessmentQuestionSelected = value
      })
    }
  }))
)

export const usePublisherStore = (selector: (state: PublisherState) => any) => useStore(publisherStore, selector)
