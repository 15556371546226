export enum Role {
  STUDENT = 1,
  TUTOR = 2,
  ADMIN = 3,
  SEO = 4,
  COMPANY = 7,
  EMPLOYEE = 8,
  COMPANY_ADMIN = 9,
  SCHOOL_STUDENT = 10,
  SCHOOL_DISTRICT_ADMIN = 11,
  CONTENT_ADMIN = 12,
  LEGACY = 13,
  SCHOOL_ADMIN = 14,
  SCHOOL_TEACHER = 15,
  HEYTUTOR_EMPLOYEE = 16,
  EMPLOYEE_HUB_ADMIN = 17
}
