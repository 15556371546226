import router from 'next/router'
import { AUTH_ENDPOINT } from 'utils/config'

type RouteParams = Record<string, string>

export function goTo(path: string, params: RouteParams = {}): Promise<boolean> {
  if (params) {
    const queryParams = Object.entries(params)
      .filter(([key]) => !path.includes(`{${key}}`))
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')

    Object.entries(params).forEach(([key, value]) => {
      path = path.replace(`{${key}}`, encodeURIComponent(value))
    })

    if (queryParams) {
      path += `?${queryParams}`
    }
  }

  return router.push(path)
}

export function goToLogin() {
  return window.location.assign(AUTH_ENDPOINT)
}
