import _ from 'lodash'
import * as yup from 'yup'
import YupLocale from './YupLocale'

// Set validation library default messages
yup.setLocale(YupLocale)

/*
yup.addMethod(yup.string, 'phone', function () {
  return this.test({
    name: 'phone',
    exclusive: true,
    message: 'Debe ser un número de teléfono válido e incluir código de área',
    test: (value) => Phone.isValid(value)
  })
})
*/

export default {
  ...yup,
  mergeSchemas: (a, b) => {
    return yup.object({
      ...a,
      ...b
    })
  }
}
