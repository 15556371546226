import { Box, Center, Image, Text } from '@chakra-ui/react'
import Head from 'next/head'

export default function StatusErrorLayout({ children }) {
  return (
    <Box minH="100vh !important" bg="white" display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Head>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Box>
        <Center flexDirection="column">
          <Image src="/images/logo-new.svg" alt="HeyTutor Logo" width={150} height={150} />
          <Text p={2} mt={3} fontSize={'24'} fontWeight={'600'} textAlign={'center'} maxW="400px">
            {children}
          </Text>
        </Center>
      </Box>
    </Box>
  )
}
