export const IS_PROD = process.env.ENV == 'prod'

export const GRAPHQL_API_ENDPOINT: string = process.env.NEXT_PUBLIC_GRAPHQL_API_ENDPOINT

export const PORTAL_HOST: string = process.env.NEXT_PUBLIC_PORTAL_HOST

export const CLIENT_ID: string = process.env.NEXT_PUBLIC_CLIENT_ID

export const USER_ENDPOINT: string = process.env.NEXT_PUBLIC_USER_ENDPOINT

export const NEXT_PUBLIC_CHANGES_NEXT_SCHOOL_YEAR: string = process.env.NEXT_PUBLIC_CHANGES_NEXT_SCHOOL_YEAR

export const USE_CHANGES_NEXT_SCHOOL_YEAR: boolean = NEXT_PUBLIC_CHANGES_NEXT_SCHOOL_YEAR == 'true'

export const AUTH_ENDPOINT: string = process.env.NEXT_PUBLIC_AUTH_ENDPOINT

export const PUSHER_APP_KEY: string = process.env.NEXT_PUBLIC_PUSHER_APP_KEY || ''

export const NEXT_PUBLIC_PUSHER_CLUSTER: string = process.env.NEXT_PUBLIC_PUSHER_CLUSTER || 'us2'

export const DISTRICT_SCHOOL_DASHBOARD_URL: string = process.env.NEXT_PUBLIC_DISTRICT_SCHOOL_DASHBOARD_URL

export const TINYMCE_API_KEY: string = process.env.NEXT_PUBLIC_TINYMCE_API_KEY

export const MAX_ATTEMPTS: string = process.env.NEXT_PUBLIC_MAX_ANSWER_ATTEMPTS || '3'

export const AUTO_SAVE_DELAY_MILLISECONDS: number = Number(process.env.NEXT_PUBLIC_AUTO_SAVE_DELAY_MILLISECONDS || 2000)

export const SLIDE_DELAY_MILLISECONDS: number = Number(process.env.NEXT_PUBLIC_SLIDE_DELAY_MILLISECONDS || 3000)

export const USE_EXPERIMENTAL_QUESTIONS_EDITOR: boolean =
  process.env.NEXT_PUBLIC_USE_EXPERIMENTAL_QUESTIONS_EDITOR !== 'false'

export const ENV: string = process.env.NEXT_PUBLIC_ENV

export const NOT_GRADED_QUESTION_ATTEMPT_LIMIT: number =
  Number(process.env.NEXT_PUBLIC_NOT_GRADED_QUESTION_ATTEMPT_LIMIT) || 3

export const USE_EXPERIMENTAL_NOTES_EDITOR: boolean = process.env.NEXT_PUBLIC_USE_EXPERIMENTAL_NOTES_EDITOR !== 'false'

// console.log('EXCHANGE_CODE_ENDPOINT: ', EXCHANGE_CODE_ENDPOINT)
// console.log('TOKEN_ENDPOINT: ', TOKEN_ENDPOINT)
// console.log('PUSHER_APP_KEY: ', PUSHER_APP_KEY)

// console.log('AUTH_ENDPOINT: ', AUTH_ENDPOINT)

// console.log('TINY_API_KEY: ', TINYMCE_API_KEY)
