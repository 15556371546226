import { Box, CircularProgress, Text } from '@chakra-ui/react'
import Image from 'next/image'

const GlobalSpinner = ({ showError }: any) => {
  const feedback = 'Authentication is taking more time than expected, if problem persist we will log you out.'

  return (
    <Box
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '30px'
      }}
    >
      <Image src="/images/logo-new.svg" alt="Logo" width={150} height={150} priority />
      <CircularProgress isIndeterminate color="#6956E5" mt={3} />
      {showError && (
        <Text p={2} bgColor={'whitesmoke'} color="red.500" textAlign={'center'} maxW="400px">
          {feedback}
        </Text>
      )}
    </Box>
  )
}

export default GlobalSpinner
