import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { logOut, useAuthenticationStore, User } from 'modules'
import { FaCaretUp, FaSignOutAlt } from 'react-icons/fa'
import { formatFirstLastName } from 'utils'

export const UserInfo = () => {
  const user: User = useAuthenticationStore((state) => state.user)

  if (!user) {
    return null
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<FaCaretUp />}
        sx={{
          fontSize: '14px',
          margin: '0 10px 0 0',
          padding: '0 10px'
        }}
      >
        {formatFirstLastName(user)?.toUpperCase()}
      </MenuButton>
      <MenuList>
        <MenuItem icon={<FaSignOutAlt />} onClick={() => logOut()}>
          Sign out
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
