import { User } from '@heytutor/common'
import { useStore } from 'zustand'
import { createStore } from 'zustand/vanilla'

interface AuthenticationState {
  user: User | null
  setUser: (user: User | null) => void
}

const AuthenticationStore = createStore<AuthenticationState>((set) => ({
  user: null,
  setUser: (user: User | null) => set({ user })
}))

export const getAuthenticationState = () => AuthenticationStore.getState()

export const useAuthenticationStore = (selector: (state: AuthenticationState) => any) =>
  useStore(AuthenticationStore, selector)
