import React, { ReactElement, useContext, useState } from 'react'

export type LoadingContextProps = {
  isLoading: boolean
  setIsLoading: (boolean) => void
  withLoading: (executor) => void
}

export type LoadingContextType = LoadingContextProps | undefined

export const LoadingContext = React.createContext<LoadingContextProps | undefined>(undefined)

export const useLoading = (): LoadingContextProps => {
  const context = useContext(LoadingContext)

  if (context === undefined) {
    throw new Error('useLoading must be used within a LoadingProvider')
  }

  return context
}

export function withLoading<T>(ComponentToWrap: React.ComponentType<T>): React.FC<T> {
  const WrappedComponent = (props: T): ReactElement => {
    const loadingProps = useLoading()

    return <ComponentToWrap {...loadingProps} {...props} />
  }

  WrappedComponent.displayName = 'withLoading_' + (ComponentToWrap.displayName || ComponentToWrap.name)

  return WrappedComponent
}

export function useLoadingContext() {
  const [isLoading, setIsLoading] = useState(false)

  return {
    isLoading,
    setIsLoading,
    withLoading: async (executor: () => Promise<void>) => {
      setIsLoading(true)

      try {
        await executor()
      } finally {
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      }
    }
  }
}
