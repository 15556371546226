import { authorizeUser, useAuthenticationStore } from '@heytutor/common'
import GlobalSpinner from 'components/global-spinner'
import { useEffect } from 'react'

export function Authenticated({ children }) {
  const user = useAuthenticationStore((state) => state.user)

  useEffect(() => {
    authorizeUser()
  }, [])

  return !user ? <GlobalSpinner /> : <>{children}</>
}
