import { getLessonPathModeStore } from 'modules/common/stores'
import { USE_CHANGES_NEXT_SCHOOL_YEAR } from './config'

const formatFirstLastName = (profile: { firstName?: string; lastName?: string } | undefined) => {
  if (profile) {
    return `${profile?.firstName || ''} ${profile?.lastName || ''}`.trim()
  } else {
    return ''
  }
}

function getIsUsingNewLessonPathMode() {
  const { usingNewLessonPathMode } = getLessonPathModeStore()

  return USE_CHANGES_NEXT_SCHOOL_YEAR || usingNewLessonPathMode
}

export { formatFirstLastName, getIsUsingNewLessonPathMode }
